import { Box } from '@material-ui/core';
import React from 'react';
import { Typo } from 'ri-components';
import { useTranslation } from 'react-i18next';

function Unauthorized() {
  const { t } = useTranslation();
  return (
    <Box textAlign='center'>
      <Typo variant='title2'>{t('401 - You are unauthorized')}</Typo>
    </Box>
  );
}

export default Unauthorized;
