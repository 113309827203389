import './polyfills';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import loadTenantManifestAssets from './utils/loadTenantManifestAssets';
import ApiConfigsContextProvider from './services/ApiConfigsContextProvider';
import AppInshightsProvider from './services/AppInsightsProvider';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

loadTenantManifestAssets();

const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ApiConfigsContextProvider>
        <AppInshightsProvider>
         <Suspense fallback={<div>Loading...</div>}>
            <I18nextProvider i18n={i18n}> 
              <App />
            </I18nextProvider>
          </Suspense> 
        </AppInshightsProvider>
      </ApiConfigsContextProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  rootElement,
);
unregister();
