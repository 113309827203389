import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { colorPalette } from 'ri-components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { isDev } from '../utils/isDev';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { grey, hyperlinkBlue } = colorPalette;

const useStyles = makeStyles({
  errorContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '17px',
    color: grey[10],
  },
  tryAgainHyperlink: {
    color: hyperlinkBlue,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    outline: 'none',
    '&:focus': {
      textDecoration: 'underline',
    },
  },
});

function ErrorFallback({ error, resetErrorBoundary }) {
  const { t } = useTranslation();
  const { errorContainer, tryAgainHyperlink } = useStyles();
  const history = useHistory();
  const appInsights = useAppInsightsContext();
  const fullLanguage = new URLSearchParams(window.location.search).get('lang') ?? 'en';
  const language =
    fullLanguage.indexOf('-') == -1 ? fullLanguage : fullLanguage.substring(0, fullLanguage.indexOf('-'));
  const reloadPage = () => {
    resetErrorBoundary();
  };
  var errorDictionary = {
    DefaultErrorMessage: {
      en: (
        <>
          The application could not be loaded. Please{' '}
          <button className={tryAgainHyperlink} onClick={reloadPage}>
            try again
          </button>
        </>
      ),
      jp: (
        <>
          {t('The application could not be loaded')}. {t('Please')}{' '}
          <button className={tryAgainHyperlink} onClick={reloadPage}>
          {t('try again')}
          </button>
        </>
      ),
    },

    JourneyTimeoutMessage: {
      en: <>Your session has timed out, please restart your claim</>,
      jp: <>{t('Your session has timed out, please restart your claim')}</>,
    },
  };
  useEffect(() => {
    if (appInsights && appInsights.core) {
      appInsights.trackException({
        exception: new Error(error),
        severityLevel: SeverityLevel.Error,
      });
    } else if (!isDev()) {
      console.warn('Insights are not connected');
    }
  }, [appInsights, error]);

  useEffect(() => history.listen(resetErrorBoundary), [history, resetErrorBoundary]);

  if (
    errorDictionary[error.message] === undefined ||
    errorDictionary[error.message][language] === undefined
  ) {
    return (
      <div role='alert' className={errorContainer}>
        <p>{errorDictionary['DefaultErrorMessage']['en']}</p>
      </div>
    );
  }
  return (
    <div role='alert' className={errorContainer}>
      <p>{errorDictionary[error.message][language]}</p>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.any,
  resetErrorBoundary: PropTypes.func,
};

export default ErrorFallback;
