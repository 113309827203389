import React from 'react';
import AuthenticatedContext from '../../auth/AuthenticatedContext';
import TenantPermissionProvider from '../../services/TenantPermissionProvider';
import HandlerLayout from './HandlerLayout';
import LoadingSuspense from '../../components/LoadingSuspense';
import RouteWrapper from '../../components/RouteWrapper';
import TenantPermissions from '../../enums/TenantPermissions';

const PolicyViewer = React.lazy(() => import('../policy-viewer/PolicyViewer'));
const ClaimStatusPage = React.lazy(() => import('./claim-status-page/ClaimStatusPage'));
const MakeAClaim = React.lazy(() => import('./make-a-claim-page/MakeAClaimPage'));

function HandlerRoutesWrapper() {
  return (
    <TenantPermissionProvider permission={TenantPermissions.Handler}>
      <AuthenticatedContext>
        <HandlerLayout>
          <LoadingSuspense>
            <RouteWrapper exact path='/' component={PolicyViewer} />
            <RouteWrapper exact path='/claim-status' component={ClaimStatusPage} />
            <RouteWrapper exact path='/make-a-claim' component={MakeAClaim} />
          </LoadingSuspense>
        </HandlerLayout>
      </AuthenticatedContext>
    </TenantPermissionProvider>
  );
}

export default HandlerRoutesWrapper;
